<template>
  <div>
    <div :class="$style.parents">
      <Title text="Родители" position="right" />

      <div :class="$style.parents__header">
        <Input
          :class="$style.parents__header_input"
          placeholder="Поиск"
          @input="(value) => inputFilter(value)"
          :value="filterValue"
        />
        <DefaultSelect
          v-model="filter.hasMinutes"
          :items="times"
          :font-size="'12px'"
          placeholder="Остаток минут"
          @input="(val) => setTime(val)"
        />
        <div :class="$style.parents__header_count">
          Общее количество пользователей: {{ overall }}
        </div>
      </div>

      <div :class="$style.table">
        <div :class="$style.table__header">
          <div :class="$style.table__header_row">
            <div
              :class="$style.table__header_column"
              v-for="headerColumn in headerColumns"
              :key="headerColumn.id"
            >
              {{ headerColumn.title }}
              <img
                v-if="headerColumn.value"
                src="@/assets/icons/arrow.svg"
                alt="arrow"
                @click="sortParents(headerColumn.value)"
              />
            </div>
          </div>
        </div>

        <div :class="$style.table__content">
          <div
            v-for="parent in list"
            :key="parent.id"
            :class="[
              $style.table__content_row,
              parent.status === 'Блокировка' && $style.table__content_row_disabled
            ]"
            @click="onSetParentDetails(parent)"
          >
            <div :class="$style.table__content_column">
              {{ parent.id }}
            </div>

            <div :class="$style.table__content_column">
              {{
                `${parent.attributes.lastName} ${parent.attributes.firstName} ${parent.attributes.middleName}`
              }}
            </div>

            <div :class="$style.table__content_column">
              {{ parent.phone }}
            </div>

            <div :class="$style.table__content_column">
              {{
                parent?.attributes?.useUnlimitedTariff
                  ? 'Безлимитный'
                  : parent?.account?.minutes
              }}
              {{ parent.isBit ? '- БИТ' : '' }}
            </div>
          </div>

          <div :class="$style.table__content_rowLine">
            <div :class="$style.table__content_line" />
            <div :class="$style.table__content_line" />
            <div :class="$style.table__content_line" />
            <div :class="$style.table__content_line" />
          </div>
        </div>
      </div>
    </div>
    <pagination
      :pageCount="Math.ceil(count / 10)"
      :prevText="''"
      :click-handler="onPageChange"
      :nextText="''"
      :containerClass="'pagination'"
      :page-class="'page'"
      v-if="paginationLoaded && count > 10"
      :initial-page="$route.query.page - 1"
    />
    <Button
      v-if="!isTechnicalSupport"
      :class="$style.create_button"
      :type="'primary'"
      @click="$router.push(`/parents/new`)"
    >
      Создать нового родителя
    </Button>
    <div v-if="isAdmin" :class="$style.bit">
      <h2>Создание пользователя с меткой BIT</h2>
      <div :class="$style.bit__container">
        <div>
          <Button
            :class="$style.bit__container_button"
            v-model="isBit"
            :type="'primary'"
            @click="trialSwitch()"
            >{{ isBit ? 'Закрыть' : 'Открыть' }}</Button
          >
        </div>
        <the-mask
          :class="$style.bit__container_input"
          v-if="isBit"
          :mask="'7(###) ###-##-##'"
          v-model="phoneForBit"
          type="tel"
          :masked="true"
          placeholder="Введите номер телефона"
        ></the-mask>
        <the-mask
          v-if="isBit"
          :class="$style.bit__container_input"
          :mask="'#########'"
          v-model="moneyForBit"
          type="tel"
          :masked="true"
          placeholder="Введите сумму"
        ></the-mask>
        <Button
          :class="$style.bit__container_button"
          v-if="isBit"
          :type="'primary'"
          @click="setUserBit()"
          >Подтвердить</Button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Title from '@/basic/Title'
import Input from '@/basic/Input'
import Button from '@/basic/Button'
import DefaultSelect from '../basic/DefaultSelect'
import users from '@/api/users'
import _ from 'lodash'
import { mapGetters, mapMutations } from 'vuex'
import userApi from '@/api/users'
import { Roles } from '@/application/constants'

import filterMixin from '@/mixins/filter.mixin'

import axios from 'axios'

const times = [
  {
    id: 0,
    name: 'нет'
  },
  {
    id: 1,
    name: 'есть'
  }
]

const headerColumns = [
  {
    id: 1,
    value: 'id',
    title: 'id'
  },
  {
    id: 2,
    value: 'fullName',
    title: 'Фио'
  },
  {
    id: 3,
    value: 'phone',
    title: 'Телефон'
  },
  {
    id: 4,
    value: 'accountMinutes',
    title: 'Остаток минут'
  }
]

export default {
  name: 'Parents',
  components: {
    Title,
    Input,
    Button,
    DefaultSelect
  },
  data() {
    return {
      list: [],
      hasMinutes: null,
      times,
      count: 0,
      isBit: false,
      moneyForBit: 0,
      phoneForBit: null,
      overall: 0,
      paginationLoaded: true,
      filterValue: '',
      filterValueThrottle: _.throttle(this.inputValueChange, 300, { leading: false }),
      headerColumns,
      pagination: {
        limit: 10,
        offset: (this.$route.query.page - 1) * 10
      },
      order: {
        field: 'id',
        by: 'desc'
      },
      validSumForBit: 478,
      validPhoneLength: 11,
      CancelToken: null,
      source: null
    }
  },
  mixins: [filterMixin],
  async created() {
    await this.overallCount(Roles.PARENT)
    await this.getList()
  },
  computed: {
    ...mapGetters(['isAdmin', 'isTechnicalSupport'])
  },
  methods: {
    ...mapMutations(['setParentDetails']),

    async overallCount(roleId) {
      try {
        const { result } = await users.overallCount(roleId)
        this.overall = result
      } catch (error) {
        console.warn(error)
        this.$store.dispatch('openModal', [
          'Alert',
          { title: 'Ошибка', message: 'Ошибка получения данных с сервера' }
        ])
      }
    },
    async getList() {
      try {
        this.CancelToken = axios.CancelToken
        this.source = this.CancelToken.source()
        const {
          data: {
            result: { count, rows }
          }
        } = await users.getParents(
          {
            pagination: this.pagination,
            order: this.order,
            filter: this.filter,
            string: this.filterValue
          },
          {
            cancelToken: this.source.token
          }
        )
        this.list = rows
        this.count = count
      } catch (error) {
        console.warn(error)
      }
    },
    inputFilter(value) {
      if (this.source) {
        this.source.cancel('[Match users operation]: CANCELED')
      }
      this.filterValueThrottle(value)
    },
    async inputValueChange(value) {
      this.filterValue = value
      this.paginationLoaded = false
      await this.onPageChange(1)
      this.paginationLoaded = true
    },
    async onPageChange(page) {
      if (Number(this.$route.query.page) !== page) {
        await this.$router.push(`?page=${page}`)
      }
      this.pagination.offset = 10 * (page - 1)
      await this.getList()
    },
    async sortParents(field) {
      if (this.order.field === field) {
        switch (this.order.by) {
          case 'desc':
            {
              this.order.by = 'asc'
            }
            break
          case 'asc':
            {
              this.order = {
                field: 'id',
                by: 'desc'
              }
            }
            break
          default:
            {
              this.order.by = 'desc'
              this.order.field = field
            }
            break
        }
      } else {
        this.order = {
          field,
          by: 'desc'
        }
      }
      await this.getList()
    },
    onSetParentDetails(parent) {
      this.setParentDetails(parent)
      this.$router.push(`/parents/${parent.id}`)
    },
    setTime(val) {
      if (val) {
        this.$set(this.filter, 'hasMinutes', val.id)
      } else {
        this.filter = {}
        this.hasMinutes = null
      }

      this.getList()
    },

    trialSwitch() {
      if (!this.isBit) {
        this.phoneForBit = null
        this.moneyForBit = null
      }
      this.isBit = !this.isBit
    },
    async setUserBit() {
      this.phoneForBit = this.phoneForBit.replace(/\D/g, '')
      if (
        this.moneyForBit >= this.validSumForBit &&
        this.phoneForBit.length === this.validPhoneLength
      ) {
        try {
          let { success } = await userApi.createBitClient({
            phone: this.phoneForBit,
            money: this.moneyForBit
          })
          if (success) {
            this.$store.dispatch('openModal', [
              'Alert',
              { title: 'Успех', message: 'Пользователь был успешно создан!' }
            ])
            await this.getList()
            this.isBit = !this.isBit
          }
        } catch (e) {
          this.$store.dispatch('openModal', [
            'Alert',
            { title: 'Ошибка', message: 'Данный пользователь уже существует' }
          ])
        }
      } else if (this.phoneForBit.length < this.validPhoneLength) {
        this.$store.dispatch('openModal', [
          'Alert',
          { title: 'Ошибка', message: 'Не полностью заполнено поле с номером телефона' }
        ])
      } else if (this.moneyForBit < this.validSumForBit) {
        this.$store.dispatch('openModal', [
          'Alert',
          { title: 'Ошибка', message: 'Сумма должа быть больше или равна 478 рублям' }
        ])
      }
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
.parents {
  &__header {
    display: flex;
    margin: 0 0 2rem;
    &_input {
      width: 15rem;
      margin: 0 2rem 0 0;
    }
    &_count {
      margin: 0 0 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .table {
    width: 100%;
    height: 85%;
    border: 1px solid $table-border;

    &__header {
      &_row {
        display: flex;
        justify-content: space-between;
        height: 2.5rem;
        border-bottom: 1px solid $table-border;
      }
      &_column {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
        border-right: 1px solid $table-border;
        font-size: 1.125rem;
        font-weight: 500;
        img {
          cursor: pointer;
          padding: 10px;
        }

        &:first-child {
          width: 7.5%;
        }

        &:nth-child(n + 2) {
          width: 18.5%;
        }

        &:last-child {
          border: none;
        }
      }
    }

    &__content {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 95%;

      &_row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 2.5rem;
        border-bottom: 1px solid $table-border;
        cursor: pointer;

        &:hover {
          background: #e7e4e4;
          opacity: 0.8;
        }
      }

      &_column {
        display: flex;
        align-items: center;
        height: 2.5rem;
        padding: 0 1rem;
        font-size: 0.875rem;
        font-weight: 500;

        &:first-child {
          width: 7.5%;
          justify-content: center;
        }

        &:nth-child(n + 2) {
          width: 18.5%;
        }

        &:last-child {
          border: none;
        }

        &_active {
          background: $red;
          border-right: 1px solid $table-border;
        }
      }

      &_rowLine {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;
      }

      &_line {
        border-right: 1px solid $table-border;

        &:first-child {
          width: 7.5%;
        }

        &:nth-child(n + 2) {
          width: 18.5%;
        }

        &:last-child {
          border: none;
        }
      }
    }
  }
}
.create_button {
  width: auto !important;
  font-size: 1rem !important;
  text-align: center !important;
  margin: 30px 0 0 !important;
}
.bit {
  margin-top: 1rem;
  &__container {
    display: flex;
    margin-top: 1rem;
    &_input {
      border: 0.1rem black solid;
      border-radius: 0.2rem;
      text-align: center;
      margin-left: 1rem;
    }
    &_button {
      width: auto !important;
      font-size: 1rem !important;
      text-align: center !important;
      margin: 0 !important;
    }
  }
}
</style>
<style lang="scss">
.pagination {
  display: flex;
  margin-top: 30px;
}
.page {
  cursor: pointer;
  background-color: #4444b7;
  margin-right: 10px;
  color: #fff;
  a {
    display: inline-block;
    padding: 10px;
  }
  &.active {
    background-color: #0909f3;
  }
}
</style>
